export const PROJECT_FOLDER = process.env.NEXT_PUBLIC_PROJECT_FOLDER;
export const PROJECT_FOLDER_PATH = PROJECT_FOLDER ? `/${PROJECT_FOLDER}` : '';

export const CDN = process.env.NEXT_PUBLIC_ASSETS_CDN || '';

export const assetPrefix = CDN ? `${CDN}${PROJECT_FOLDER_PATH}` : '';
export const R2_ASSET_PREFIX = 'https://web-assets.stockbit.com';

export const FB_APP_ID = process.env.NEXT_PUBLIC_FACEBOOK_APPID || '';
export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID || '';
export const siteUrl = process.env.NEXT_PUBLIC_SITE_URL || '';
export const STAGE = process.env.NODE_ENV || 'staging';
export const CLEVERTAP_ID = process.env.NEXT_PUBLIC_CLEVERTAP_ID;
export const AWS_AVATAR_BUCKET =
  process.env.NEXT_PUBLIC_AWS_AVATAR_BUCKET_PUBLIC || '';
export const USER_AGENT = process.env.NEXT_PUBLIC_USER_AGENT || 'webapp-api';
export const CRISP_ID = process.env.NEXT_PUBLIC_WEBSITE_ID_CRISP || '';

export const isClient = () => typeof window !== 'undefined';

export const isProduction = process.env.NODE_ENV === 'production';

// Market hour
export const OPEN_HOUR = 9;
export const CLOSE_HOUR = 15;

export const isMarketOpen = () => {
  const currentTime = new Date().getHours();

  // return currentTime >= OPEN_HOUR && currentTime <= CLOSE_HOUR;
  return currentTime >= OPEN_HOUR;
};

export const PROXY_CLIENT_CONFIG = {
  url: process.env.NEXT_PUBLIC_UNLEASH_URL,
  clientKey: process.env.NEXT_PUBLIC_UNLEASH_KEY,
  appName: process.env.NEXT_PUBLIC_UNLEASH_APP_NAME || 'staging',
  environment: process.env.NEXT_PUBLIC_UNLEASH_ENVIRONMENT || 'staging',
  disableMetrics: true,
  refreshInterval: 30,
};

export const STRUCTURED_DATA = {
  '@context': 'http://schema.org',
  '@type': 'Corporation',
  name: 'Stockbit',
  legalName: 'Stockbit',
  description:
    "Stockbit adalah komunitas investasi di Indonesia. Stockbit memberi ruang bagi investor dan trader untuk berbagi ide, berita dan informasi keuangan lainya secara real-time. Pengguna bisa membuat profil dan mengikuti saham tertentu atau investor favorit untuk mendapatkan informasi berharga dan bertukar pikiran. Fitur utama dari Stockbit yaitu 'stream' terdiri dari ide-ide, grafik saham, link dan data keuangan lainya. Stockbit menggunakan tanda '$' sebelum kode saham sebagai penandaan ide untuk mempermudah pengguna dalam mencari informasi tentang saham individu tertentu. Platform Stockbit juga terintegrasi dengan platform social media lainya, seperti Twitter dan Facebook, sehingga mudah untuk berbagi ide-ide di Stockbit ke akun Twitter dan Facebook pengguna. Stockbit dirancang dengan fitur yang terfokus dengan keperilakuan investor dan trader sehingga akan lebih berguna sebagai social media khusus investasi.",
  logo: 'https://assets-nextjs.stockbit.com/img/favicon.ico',
  url: 'https://stockbit.com',
  telephone: '+6221 57906242',
  sameAs: [
    'https://twitter.com/Stockbit',
    'https://www.linkedin.com/company/stockbit',
    'https://www.facebook.com/Stockbit',
    'https://www.youtube.com/c/Stockbit',
    'https://instagram.com/stockbit',
    'https://www.crunchbase.com/organization/stockbit',
  ],
  address: {
    '@type': 'PostalAddress',
    streetAddress:
      'RDTX Square 33rd Floor, Jl. Prof. Dr. Satrio No 164 - Jakarta',
    addressLocality: 'Jakarta Selatan',
    postalCode: '12930',
    addressCountry: 'Indonesia',
  },
};

export const assetsStatic = `${process.env.NEXT_PUBLIC_ASSETS_STATIC}`;
