import Script from 'next/script';
import { assetPrefix } from 'constants/app';

const NewRelic = () => (
  <>
    {process.env.NEXT_NEW_RELIC_ENV === 'production' ? (
      <Script
        type="text/javascript"
        strategy="beforeInteractive"
        src={`${assetPrefix}/js/new-relic.js`}
      />
    ) : (
      <Script
        type="text/javascript"
        strategy="beforeInteractive"
        src={`${assetPrefix}/js/new-relic.dev.js`}
      />
    )}
  </>
);

export default NewRelic;
